<template>
  <section class="hero is-fullheight">
    <div class="hero-body is-align-items-flex-start">
      <div class="container">
        <div class="columns is-multiline">
          <div class="column is-12">
            <div class="level">
              <div class="level-left">
                <div class="level-item">
                  <h1 class="title_view">
                    Usuarios
                  </h1>
                </div>
              </div>
              <div class="level-right">
                <div class="level-item">
                  <input
                    class="input"
                    type="text"
                    placeholder="Buscador"
                    v-model="search"
                    @change="searchUser"
                  >
                </div>
                <div class="level-item">
                  <button class="button btn_aqua">
                    Descargar
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-12 is_grid pb-0">
            <div class="table-container">
              <BaseTable
                :columns="columnsData"
                :data="usersList"
                :loading=loading
              >
                <div slot="status" slot-scope="scope">
                  <p
                    class="text_status has-text-weight-medium"
                    :class="[ scope.row.active ? 'has_success' : 'has_danger' ]"
                  >
                    {{ scope.row.active ? 'Activo' : 'Inactivo'}}
                  </p>
                </div>
              </BaseTable>
            </div>
          </div>
          <div class="column is-12 pt-0">
            <div class="pagination_container">
              <BasePagination
                :page="page"
                :pages="parseInt(pages)"
                :action="paginator"
                :loading="loading"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment'
export default {
  name: 'CustomersMain',
  components: {
    BaseTable: () => import('@/components/UI/BaseTable.vue'),
    BasePagination: () => import('@/components/UI/BasePagination')
  },
  data () {
    return {
      loading: true,
      page: 1,
      pages: 1,
      limit: 10,
      search: '',
      columnsData: [
        { id: 'id', header: 'ID' },
        {
          id: 'name',
          header: 'Usuario',
          link: true,
          type: 'link',
          class: 'link',
          route: (row) => ({ name: 'detalle-usuario', params: { userId: row.id } }),
          accessor: (row) => `${row.name} ${row.last_name} ${row.second_last_name}`
        },
        { id: 'businessName', header: 'Razón social', accessor: (row) => `${row.business_name || 'N/A'}` },
        { id: 'phone', header: 'Telefono ' },
        { id: 'email', header: 'Correo ' },
        { id: 'active', name: 'status', header: 'Estatus', class: 'has-text-centered' },
        {
          id: 'created_at',
          header: 'Fecha Registro',
          class: 'has-text-centered',
          accessor: (row) => moment(row.created_at).format('YYYY-MM-DD')
        }
      ],
      usersList: []
    }
  },
  methods: {
    ...mapActions(['getUserList']),
    async getCustomerList (page = 1, limit = 10, search = '') {
      this.loading = true
      const resp = await this.getUserList({ page, limit, search })
      if (resp.success) {
        this.usersList = resp.payload
        this.pages = resp.totalPages
      }
      this.loading = false
    },
    async searchUser () {
      this.page = 1
      await this.getCustomerList(this.page, this.limit, this.search)
    },
    async paginator (page) {
      this.page = page
      await this.getCustomerList(this.page, this.limit, this.search)
    }
  },
  beforeMount () {
    this.getCustomerList(this.page, this.limit, this.search)
  }
}
</script>

<style lang="scss" scoped>
  .input {
    border-radius: 3px;
    background-color: #fff;
    border: none;
    min-width: 300px;
  }
  .btn_aqua {
    height: 3em;
  }
  .text_status {
    font-size: 13px;
    font-weight: 500;
    line-height: 1.54;
    letter-spacing: 0.13px;
  }
</style>
